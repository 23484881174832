/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import image from '../../../static/images/404-image.png';
import RichText from '../../elements/richText';

const ErrorContainer = styled.div`
  padding: 0px 60px;
  max-width: 920px;
  margin: auto;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0px 20px;
  }
`;

const ErrorHead = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorNumber = styled.div`
  color: ${props => props.theme.colours.nimbleRed};
  font-family: ${props => props.theme.font.nimbleBoldFont};
  font-size: 150px;
  line-height: 140px;
  margin-top: 35px;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    font-size: 125px;
    line-height: 120px;
    margin-top: 25px;
  }

  @media (max-width: 460px) {
    font-size: 80px;
    line-height: 70px;
  }
`;

const ErrorImage = styled.img`
  width: 130px;
  margin-left: 5px;
  margin-right: 5px;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    width: 110px;
  }

  @media (max-width: 460px) {
    width: 70px;
  }
`;

const ErrorText = styled.div`
  margin-bottom: 60px;
`;

const ErrorTitle = styled.h1`
  font-family: ${props => props.theme.font.nimbleFont};
  font-style: normal;
  font-size: 40px;
  line-height: 50px;
  color: ${props => props.theme.colours.slate};
  text-transform: none;

  @media (max-width:1470px) {
    font-size: 35px;
    line-height: 45px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    font-size: 28px;
    line-height: 35px;
  }

  @media (max-width: 460px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const NimbleError404Page = ({
  componentData
}) => {
  const textData = componentData.filter(
    data => data.componentId === 'Basic Component - Error 404'
  );
  return (
    <>
      <ErrorContainer>
        <ErrorHead>
          <ErrorNumber>4</ErrorNumber>
          <ErrorImage src={image} alt="0" />
          <ErrorNumber>4</ErrorNumber>
        </ErrorHead>

        <ErrorText>
          <ErrorTitle>{textData[0].basicHeading}</ErrorTitle>
          {textData[0].subheading && <h2 className="h3">{textData[0].subheading}</h2>}
          {textData[0].text
            && (
              <div><RichText text={textData[0].text} /></div>
            )}
        </ErrorText>
      </ErrorContainer>
    </>
  );
};

export default NimbleError404Page;
